import axios from "axios";
import { ILinePage, ILinePageConfigCreateDto, ILinePageSectionEditDto, ILinePageStatistics } from "../interfaces/endpoints/ILinePage";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { serialize } from "../utils/serializeToQueryString";
import { getAllBlogsType } from "./Blogs.service";

export const getLinePageById = async (
  id: string
): Promise<IAxiosResponse<ILinePage>> => {
  return axios.get<ILinePage>(`LinePage/${id}`);
};

export const getAllLinePages = async (
  att: getAllBlogsType
): Promise<IAxiosResponse<IPagination<ILinePage>>> => {
  const query = serialize( { ...att } );
  return axios.get<IPagination<ILinePage>>(`LinePage${query}`);
};

/**
 * Get all sections of a specific Line Page
 * @param linePageId - ID of the Line Page
 */
export const getLinePageSections = async (
  linePageId: number
): Promise<IAxiosResponse<ILinePageSectionEditDto[]>> => {
  return axios.get<ILinePageSectionEditDto[]>(`/LinePage/${linePageId}/Sections`);
};

/**
 * Edit a specific Line Page Section
 * @param linepageId - ID of the Line Page Section
 * @param data - Line Page Section data to be updated
 */
export const editLinePageSection = async (
  linepageId: string,
  data: ILinePageSectionEditDto[]
): Promise<IAxiosResponse<number>> => {
  return axios.post<number>(`/LinePage/${linepageId}/Sections`, data);
};

/**
 * Get statistics of a specific Line Page
 * @param linePageId - ID of the Line Page
 */
export const getLinePageStatistics = async (
  linePageId: number
): Promise<IAxiosResponse<ILinePageStatistics>> => {
  return axios.get<ILinePageStatistics>(`/LinePage/${linePageId}/Statistics`);
};

/**
 * Update statistics of a specific Line Page
 * @param linePageId - ID of the Line Page
 * @param data - Line Page Statistics data to be updated
 */
export const updateLinePageStatistics = async (
  linePageId: number,
  data: ILinePageStatistics
): Promise<IAxiosResponse<number>> => {
  return axios.put<number>(`/LinePage/${linePageId}/Statistics`, data);
};

/**
 * Get config of a specific Line Page
 * @param linePageId - ID of the Line Page
 */
export const getLinePageConfig = async (
  linePageId: number
): Promise<IAxiosResponse<ILinePageConfigCreateDto>> => {
  return axios.get<ILinePageConfigCreateDto>(`/LinePage/${linePageId}/Config`);
};

/**
 * Update config of a specific Line Page
 * @param linePageId - ID of the Line Page
 * @param data - Line Page Config data to be updated
 */
export const updateLinePageConfig = async (
  linePageId: number,
  data: ILinePageConfigCreateDto
): Promise<IAxiosResponse<number>> => {
  return axios.put<number>(`/LinePage/${linePageId}/Config`, data);
};

/**
 * Regenerate a specific Line Page
 * @param linePageId - ID of the Line Page
 */
export const regenerateLinePage = async (
  linePageId: number
): Promise<IAxiosResponse<number>> => {
  return axios.get<number>(`/LinePage/${linePageId}/Regenerate`);
};
