import { ArrowPathIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import { getAllLinePages, regenerateLinePage } from "../../services/LinePages.service";
import AuditAuthor from "../audit-author/AuditAuthor";
import AutoSuggest from "../shared/AutoSuggest";
import DeleteModal from "../ui/DeleteModal";
import IconBtn from "../ui/IconBtn";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";

const columns: (string | number)[] = [
  "ID",
  "Nisja",
  "Destinacioni",
  "Detajet",
  "Veprimet"
];

const LinePagesTable = () => {
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");

  const { response, loading, handlePageChange, page } = useSearch(
    getAllLinePages,
    {
      otherQuery: { from, to }
    }
  );

  const { pageIndex, state }: any = usePageIndex();

  return (
    <div>
      <div className="flex flex-row gap-4 w-[50%] mb-2">
        <AutoSuggest placeholder="Nisja" onChange={setFrom} />
        <AutoSuggest placeholder="Destinacioni" onChange={setTo} />
      </div>
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        {/* Table */}
        <Table titles={columns} style="hover" loading={loading}>
          {response?.data.result?.length ? (
            response?.data.result?.map((row) => {
              const highlighted = +state?.params?.id === row.id;
              return (
                <Row
                  key={row.id}
                  id={row.id}
                  className={`${highlighted && "highlighted"}`}
                  fromName={row.fromName}
                  toName={row.toName}
                  auditAuthor={
                    <AuditAuthor
                      createdByName={row.auditAuthor.createdByName}
                      createdByLastName={row.auditAuthor.createdByLastName}
                      updatedByName={row.auditAuthor.updatedByName}
                      updatedByLastName={row.auditAuthor.updatedByLastName}
                      insertDate={row.auditAuthor.insertDate}
                      updateDate={row.auditAuthor.updateDate}
                    />
                  }
                  actions={
                    <span className="flex gap-2">
                      <Can permissions="linepages.linepage.update">
                        <Link
                          to={`/line-pages/edit/${row.id}`}
                          state={{
                            crumbs: ["Informatat për linjën", "Edito Linjën"],
                            page: pageIndex
                          }}
                        >
                          <IconBtn className="w-6 h-6">
                            <PencilIcon className="w-4 h-4 text-neutral" />
                          </IconBtn>
                        </Link>
                      </Can>
                      <Can permissions="linepages.linepage.update">
                        <DeleteModal
                          params={row.id}
                          title="Konfirmo rigjenerimin"
                          paragraph="Ky veprim do ti rigjeneroj statistikat dhe generic FAQ."
                          handleDelete={regenerateLinePage}
                          successMessage="Line page u rigjenerua me sukses"
                          errorMessage="Ndodhi një gabim gjatë rigjenerimit"
                          loadingMessage="Duke rigjeneruar line page..."
                          indicator={
                            <IconBtn className="w-6 h-6">
                              <ArrowPathIcon className="w-4 h-4 text-neutral" />
                            </IconBtn>
                          }
                        />
                      </Can>
                    </span>
                  }
                />
              );
            })
          ) : (
            <></>
          )}
        </Table>

        {/* Pagination */}
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result.length}
          page={page}
          pageSize={10}
        />
      </div>
    </div>
  );
};

export default LinePagesTable;
